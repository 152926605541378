@import "@angular/material/legacy-prebuilt-themes/indigo-pink.css";

@media print {
  .mat-drawer-container , .mat-drawer-content {
    overflow: visible !important;
  }
  mat-toolbar {
    display: none !important;
  }
}

body {
  font-family: Poppins, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
}

h1,
h3,
h4 {
  width: 100%;
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

mat-form-field {
  width: 100%;
}

.div-buttons {
  width: 100%;
  text-align: center;
}

th {
  color: black !important;
  background: #cccccc !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

tr:hover {
  background: #f5f5f5;
}

.container-buttons {
  display: flex !important;
  justify-content: space-around !important;
}

.formFilter {
  margin-bottom: -30px !important;
}

.apps,
.content {
  background-color: white;
  margin-bottom: 10px;
}

td.mat-cell {
  padding: 0px 3px !important;
}

/*******************
Paddings
*******************/
html body {
  .p-0 {
    padding: 0px;
  }

  .p-5 {
    padding: 5px;
  }

  .p-10 {
    padding: 10px;
  }

  .p-20 {
    padding: 20px;
  }

  .p-30 {
    padding: 30px;
  }

  .p-l-0 {
    padding-left: 0px;
  }

  .p-l-10 {
    padding-left: 10px;
  }

  .p-l-20 {
    padding-left: 20px;
  }

  .p-r-0 {
    padding-right: 0px;
  }

  .p-r-10 {
    padding-right: 10px;
  }

  .p-r-20 {
    padding-right: 20px;
  }

  .p-r-30 {
    padding-right: 30px;
  }

  .p-r-40 {
    padding-right: 40px;
  }

  .p-t-0 {
    padding-top: 0px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-b-0 {
    padding-bottom: 0px;
  }

  .p-b-5 {
    padding-bottom: 5px;
  }

  .p-b-10 {
    padding-bottom: 10px;
  }

  .p-b-20 {
    padding-bottom: 20px;
  }

  .p-b-30 {
    padding-bottom: 30px;
  }

  .p-b-40 {
    padding-bottom: 40px;
  }
}

/*******************
  Margin
  *******************/
html body {
  .m-0 {
    margin: 0px;
  }

  .m-5 {
    margin: 5px;
  }

  .m-10 {
    margin: 10px;
  }

  .m-l-5 {
    margin-left: 5px;
  }

  .m-l-10 {
    margin-left: 10px;
  }

  .m-l-15 {
    margin-left: 15px;
  }

  .m-l-20 {
    margin-left: 20px;
  }

  .m-l-30 {
    margin-left: 30px;
  }

  .m-l-40 {
    margin-left: 40px;
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .m-r-10 {
    margin-right: 10px;
  }

  .m-r-15 {
    margin-right: 15px;
  }

  .m-r-20 {
    margin-right: 20px;
  }

  .m-r-30 {
    margin-right: 30px;
  }

  .m-r-40 {
    margin-right: 40px;
  }

  .m-t-0 {
    margin-top: 0px;
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-t-40 {
    margin-top: 40px;
  }

  .m-b-0 {
    margin-bottom: 0px;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .m-b-40 {
    margin-bottom: 40px;
  }
}
